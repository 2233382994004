import Map from '@/assets/img/about-us/fund_map_2018@2x.png';
import MapTxt from '@/assets/img/about-us/fund_map_txt_2018@2x.png';
import MapTxtM from '@/assets/img/about-us/fund_map_txtM_2018@2x.png';
import MapM from '@/assets/img/about-us/fund_mapM_2018@2x.png';
import Obj1 from '@/assets/img/about-us/fund_obj1@2x.png';
import Obj2 from '@/assets/img/about-us/fund_obj2@2x.png';
import Obj3 from '@/assets/img/about-us/fund_obj3@2x.png';
import Button from '@/components/Button';
import {
  Render,
  Article,
  ChartContainer,
  Summary,
  TableContainer,
  NoticeBox,
  MapContainer,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import { ProcessList } from '@/components/ProcessList';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import React from 'react';
import styled from 'styled-components';

const incomeData = [
  {
    type: `모금 수입`,
    data: [
      {
        name: `정기후원`,
        amount: 123384325732,
        tip: `매월 정해진 금액을 후원하는 것`,
      },
      {
        name: `일시후원`,
        amount: 5669370432,
        tip: `개인이나 단체가 비정기적으로 후원하는 것`,
      },
      {
        name: `기업모금`,
        amount: 4584585016,
      },
      {
        name: `재단/비영리기관`,
        amount: 1141619476,
      },
    ],
  },
  {
    type: `모금 외 수입`,
    data: [
      {
        name: `본부 지원금`,
        amount: 9704587975,
      },
      {
        name: `이자수익 및 기타`,
        amount: 790906287,
        tip: `예금이자수익 등`,
      },
      {
        name: `수익사업`,
        amount: 382300457,
      },
    ],
  },
];
const incomeTotalArry = [0, 0];
incomeData.map((row, index) => {
  row.data.map((row2) => {
    incomeTotalArry[index] += row2.amount;
  });
});
const incomeTotal = incomeTotalArry[0] + incomeTotalArry[1];

const incomePerArry = [0, 0];
incomeData.map((row, index) => {
  row.data.map((row2) => {
    incomePerArry[index] += (row2.amount / incomeTotal) * 100;
  });
});

const spendingData = [
  {
    type: `고유목적사업`,
    data: [
      {
        name: `개발도상국 지원비`,
        amount: 114574043886,
        rate: 78.7,
        tip: `157개 나라의 유니세프 프로그램 지원비`,
      },
      {
        name: `사업비`,
        amount: 13373712238,
        rate: 9.0,
        tip: `후원자관리 및 제반 홍보활동 포함`,
      },
      {
        name: `아동권리 증진`,
        amount: 953318619,
        rate: 0.7,
        tip: `국내아동권리 증진 및 교육 프로그램`,
      },
      {
        name: `관리 운영비`,
        amount: 6538587342,
        rate: 4.5,
        tip: `법인 운영관련 비용 및 인건비 등`,
      },
      {
        name: `본부 지원금`,
        amount: 9691930200,
        rate: 6.7,
      },
      {
        name: `기타`,
        amount: 49651008,
        rate: 0.1,
      },
    ],
  },
  {
    type: `차기년도 사업 준비금`,
    rowspan: true,
    data: [
      {
        name: `차기년도 사업 준비금`,
        amount: 476452082,
        rate: 0.3,
      },
    ],
  },
];
const spendingTotalArry = [0, 0, 0];
spendingData.map((row, index) => {
  row.data.map((row2) => {
    spendingTotalArry[index] += row2.amount;
  });
});
const spendingTotal =
  spendingTotalArry[0] + spendingTotalArry[1] + spendingTotalArry[2];

const processData = [
  {
    id: 1,
    img: Obj1,
    title: `7,123,500,000 원`,
    desc: [
      {
        dec: `<strong>HIV/AIDS</strong> - 에이즈`,
      },
    ],
  },
  {
    id: 2,
    img: Obj2,
    title: `1,051,150,000 원`,
    desc: [
      {
        dec: `<strong>Schools for Asia</strong> - 교육사업`,
      },
    ],
  },
  {
    id: 3,
    img: Obj3,
    title: `1,465,646,762 원`,
    desc: [
      {
        dec: `<strong>ENDviolence</strong> - 어린이 보호`,
      },
    ],
  },
];

const developedData = [
  {
    id: 0,
    continents: `아시아`,
    contryData: [
      {
        id: 0,
        contry: `북한`,
        amount: `3,370,500,000`,
        type: `보건`,
      },
      {
        id: 1,
        contry: `몽골`,
        amount: `654,360,000`,
        type: `교육, 어린이 보호`,
      },
      {
        id: 2,
        contry: `베트남`,
        amount: `452,291,113`,
        type: `교육`,
      },
      {
        id: 3,
        contry: `인도네시아`,
        amount: `378,271,428`,
        type: `영양, 보건, 식수와 위생, 어린이 보호, 교육`,
      },
      {
        id: 4,
        contry: `방글라데시`,
        amount: `353,331,827`,
        type: `교육, 어린이 보호`,
      },
      {
        id: 5,
        contry: `라오스`,
        amount: `336,600,000`,
        type: `식수와 위생`,
      },
      {
        id: 6,
        contry: `부탄`,
        amount: `207,320,000`,
        type: `교육, 식수와 위생`,
      },
    ],
  },
  {
    id: 1,
    continents: `아프리카`,
    contryData: [
      {
        id: 0,
        contry: `시에라리온`,
        amount: `337,050,000`,
        type: `보건, 식수와 위생, 영양, 에이즈`,
      },
      {
        id: 1,
        contry: `잠비아`,
        amount: `337,050,000`,
        type: `교육`,
      },
      {
        id: 2,
        contry: `카메룬`,
        amount: `337,050,000`,
        type: `보건`,
      },
      {
        id: 3,
        contry: `차드`,
        amount: `337,050,000`,
        type: `교육`,
      },
      {
        id: 4,
        contry: `알제리`,
        amount: `50,000,000`,
        type: `교육`,
      },
    ],
  },
  {
    id: 2,
    continents: `중동`,
    contryData: [
      {
        id: 0,
        contry: `이란`,
        amount: `337,050,000`,
        type: `보건, 영양, 교육, 어린이 보호`,
      },
    ],
  },
  {
    id: 3,
    continents: `유럽`,
    contryData: [
      {
        id: 0,
        contry: `세르비아`,
        amount: `224,700,000`,
        type: `어린이 보호`,
      },
    ],
  },
];
const TblWarp = styled.div`
  overflow-x: auto;
  table {
    min-width: 470px;
  }
`;
const numberFormat = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);

const ReportKorea2018: React.FC = (props) => (
  <Render>
    <ul className="btn-flex flex">
      <li>
        <LinkSafe
          to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2018_audit_report.pdf&svrFileNm=2018_audit_report.pdf`}
        >
          <Button outline full ico="down" className="btn-report">
            <span className="ico">감사보고서</span>
          </Button>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe
          to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2018_report.pdf&svrFileNm=2018_report.pdf`}
        >
          <Button outline full ico="down" className="btn-report">
            <span className="ico">연차보고서</span>
          </Button>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe
          to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2018_performance_report.pdf&svrFileNm=2018_performance_report.pdf`}
        >
          <Button outline full ico="down" className="btn-report">
            <span className="ico">
              기부금 모금액 및 <br />
              활용 실적
            </span>
          </Button>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe
          to="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/sf/a/c/UTESFACI01.xml"
          target="_blank"
        >
          <Button outline full ico="share" className="btn-report">
            <span className="ico">국세청 공시 시스템</span>
          </Button>
        </LinkSafe>
      </li>
    </ul>

    <SectionHeader className="with-desc">
      <h2>
        <Tit size="s1">
          유니세프한국위원회는
          <br />
          후원금을 투명하게 운영합니다
        </Tit>
      </h2>
    </SectionHeader>

    <Article>
      <h3>
        <Tit size="s2">정기감사</Tit>
      </h3>
      <p className="dec">
        유니세프한국위원회는 내부 감사, 유니세프 본부 및 외부회계법인 감사를
        통해 후원금 사용의 투명성을 검증받습니다.
      </p>
      <NoticeBox>
        <ul className="blit-list">
          <li>
            <strong>유니세프한국위원회 내부 감사</strong> 연 1회 이사회 감사
          </li>
          <li>
            <strong>유니세프 본부 감사</strong> 연 1회 감사
          </li>
          <li>
            <strong>외부 감사</strong> 연 1회 (2018년 이촌회계법인 감사)
          </li>
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3>
        <Tit size="s2">국세청 공시</Tit>
      </h3>
      <p className="dec">
        공익법인 결산서류 공시 의무에 따라 매년 국세청에 결산 서류, 연간 기부금
        모금액 및 활용실적 등을 제출하여 국세청 웹사이트에 공시합니다.
      </p>
    </Article>

    <SectionHeader className="with-desc">
      <h2>
        <Tit size="s1">유니세프한국위원회 결산</Tit>
      </h2>
    </SectionHeader>

    <Article>
      <h3 className="sky-bd">
        <Tit size="s2" color="sky">
          2018 수입
        </Tit>
      </h3>
      <ChartContainer>
        <div className="m-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 20px;'>수입</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              incomeTotal,
            )}</b><b style='font-size:20px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: incomeData[0].data[0].name,
                    y: (incomeData[0].data[0].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[0].data[1].name,
                    y: (incomeData[0].data[1].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[0].data[2].name,
                    y: (incomeData[0].data[2].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[0].data[3].name,
                    y: (incomeData[0].data[3].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[1].data[0].name,
                    y: (incomeData[1].data[0].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[1].data[1].name,
                    y: (incomeData[1].data[1].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[1].data[2].name,
                    y: (incomeData[1].data[2].amount / incomeTotal) * 100,
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="pc-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 14px;'>수입</span><br><b style='font-size: 20px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              incomeTotal,
            )}</b><b style='font-size:14px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: incomeData[0].data[0].name,
                    y: (incomeData[0].data[0].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[0].data[1].name,
                    y: (incomeData[0].data[1].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[0].data[2].name,
                    y: (incomeData[0].data[2].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[0].data[3].name,
                    y: (incomeData[0].data[3].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[1].data[0].name,
                    y: (incomeData[1].data[0].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[1].data[1].name,
                    y: (incomeData[1].data[1].amount / incomeTotal) * 100,
                  },
                  {
                    name: incomeData[1].data[2].name,
                    y: (incomeData[1].data[2].amount / incomeTotal) * 100,
                  },
                ],
              },
            ]}
          />
        </div>
      </ChartContainer>
      <TableContainer>
        <table className="tbl1">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
            <col className="col3" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>금액(원)</th>
              <th>비율</th>
            </tr>
          </thead>
          {incomeData.map((row, index) => (
            <tbody key={index}>
              <tr>
                <th colSpan="3">
                  <strong>{row.type}</strong>
                </th>
              </tr>
              {row.data.map((row2) => (
                <tr>
                  <td>
                    {row2.name} {row2.tip && `*`}
                  </td>
                  <td>
                    <p className="price">{numberFormat(row2.amount)}</p>
                  </td>
                  <td>
                    <p className="price">
                      {((row2.amount / incomeTotal) * 100).toFixed(1)}%
                    </p>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>소계</strong>
                </td>
                <td>
                  <p className="price">
                    {numberFormat(incomeTotalArry[index])}
                  </p>
                </td>
                <td>
                  <p className="price">{incomePerArry[index].toFixed(1)}%</p>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </TableContainer>
      <NoticeBox>
        <ul className="blit-list">
          {incomeData.map((row, index) => (
            <>
              {row.data.map((row2, index2) => (
                <>
                  {row2.tip && (
                    <li>
                      <strong>{row2.name} : </strong>
                      {row2.tip}
                    </li>
                  )}
                </>
              ))}
            </>
          ))}
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3 className="sky-bd">
        <Tit size="s2" color="sky">
          2018 지출
        </Tit>
      </h3>
      <ChartContainer>
        <div className="m-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 20px;'>지출</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              spendingTotal,
            )}</b><b style='font-size:20px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: spendingData[0].data[0].name,
                    y: spendingData[0].data[0].rate,
                  },
                  {
                    name: spendingData[0].data[1].name,
                    y: spendingData[0].data[1].rate,
                  },
                  {
                    name: spendingData[0].data[2].name,
                    y: spendingData[0].data[2].rate,
                  },
                  {
                    name: spendingData[0].data[3].name,
                    y: spendingData[0].data[3].rate,
                  },
                  {
                    name: spendingData[0].data[4].name,
                    y: spendingData[0].data[4].rate,
                  },
                  {
                    name: spendingData[0].data[5].name,
                    y: spendingData[0].data[5].rate,
                  },
                  {
                    name: spendingData[1].data[0].name,
                    y: spendingData[1].data[0].rate,
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="pc-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 14px;'>지출</span><br><b style='font-size: 20px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              spendingTotal,
            )}</b><b style='font-size:14px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: spendingData[0].data[0].name,
                    y: spendingData[0].data[0].rate,
                  },
                  {
                    name: spendingData[0].data[1].name,
                    y: spendingData[0].data[1].rate,
                  },
                  {
                    name: spendingData[0].data[2].name,
                    y: spendingData[0].data[2].rate,
                  },
                  {
                    name: spendingData[0].data[3].name,
                    y: spendingData[0].data[3].rate,
                  },
                  {
                    name: spendingData[0].data[4].name,
                    y: spendingData[0].data[4].rate,
                  },
                  {
                    name: spendingData[0].data[5].name,
                    y: spendingData[0].data[5].rate,
                  },
                  {
                    name: spendingData[1].data[0].name,
                    y: spendingData[1].data[0].rate,
                  },
                ],
              },
            ]}
          />
        </div>
      </ChartContainer>

      <TableContainer>
        <table className="tbl1">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
            <col className="col3" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>금액(원)</th>
              <th>비율</th>
            </tr>
          </thead>
          {spendingData.map((row, index) => (
            <tbody key={index}>
              {!row.rowspan ? (
                <tr>
                  <th colSpan="3">
                    <strong>{row.type}</strong>
                  </th>
                </tr>
              ) : (
                <tr>
                  <th>
                    <strong>{row.type}</strong>
                  </th>
                  <th>
                    <p className="price">{numberFormat(row.data[0].amount)}</p>
                  </th>
                  <th>
                    <p className="price">{row.data[0].rate}%</p>
                  </th>
                </tr>
              )}
              {!row.rowspan &&
                row.data.map((row2) => (
                  <tr>
                    <td>
                      {row2.name} {row2.tip && `*`}
                    </td>
                    <td>
                      <p className="price">{numberFormat(row2.amount)}</p>
                    </td>
                    <td>
                      <p className="price">{row2.rate.toFixed(1)}%</p>
                    </td>
                  </tr>
                ))}
            </tbody>
          ))}
        </table>
      </TableContainer>
      <NoticeBox>
        <ul className="blit-list">
          {spendingData.map((row, index) => (
            <>
              {row.data &&
                row.data.map((row2, index2) => (
                  <>
                    {row2.tip && (
                      <li>
                        <strong>{row2.name} : </strong>
                        {row2.tip}
                      </li>
                    )}
                  </>
                ))}
            </>
          ))}
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3 className="sky-bd">
        <Tit size="s2" color="sky">
          2018 개발도상국 지원비
        </Tit>
      </h3>
      <TableContainer>
        <Summary>개발도상국 어린이 지원 총계 : 114,574,043,886원</Summary>
        <TblWarp>
          <table className="tbl3">
            <colgroup>
              <col className="col1" />
              <col className="col2" />
              <col className="col3" />
              <col className="col4" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>금액(원)</th>
                <th>지원사업</th>
                <th>비율</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <strong>일반사업비</strong>
                </th>
                <th>
                  <p className="price">97,221,122,756 </p>
                </th>
                <th />
                <th>
                  <p className="price">85%</p>
                </th>
              </tr>
              <tr>
                <th>
                  <strong>지정사업비</strong>
                </th>
                <th>
                  <p className="price">17,352,921,130</p>
                </th>
                <th />
                <th>
                  <p className="price">15%</p>
                </th>
              </tr>
              <tr>
                <th colSpan="4">
                  <strong>국가별 지정사업비</strong>
                </th>
              </tr>
            </tbody>
            {developedData.map((row, index) => (
              <tbody key={index} className="developed-tbody">
                <tr>
                  <td colSpan="4">
                    <p className="continents">
                      <strong>{row.continents}</strong>
                    </p>
                  </td>
                </tr>
                {row.contryData.map((row2, index2) => (
                  <tr key={index2}>
                    <td>
                      <p className="country">{row2.contry}</p>
                    </td>
                    <td>
                      <p className="price">{row2.amount}</p>
                    </td>
                    <td colSpan="2">
                      <p className="dec">{row2.type}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
            <tbody>
              <tr>
                <th colSpan="4">
                  <strong>글로벌 캠페인</strong>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="country">HIV/AIDS</p>
                </td>
                <td>
                  <p className="price">7,123,500,000</p>
                </td>
                <td colSpan="2">
                  <p className="dec">에이즈</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="country">Schools for Asia</p>
                </td>
                <td>
                  <p className="price">1,051,150,000</p>
                </td>
                <td colSpan="2">
                  <p className="dec">교육</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="country">ENDviolence</p>
                </td>
                <td>
                  <p className="price">1,465,646,762</p>
                </td>
                <td colSpan="2">
                  <p className="dec">어린이 보호</p>
                </td>
              </tr>
            </tbody>
          </table>
        </TblWarp>
      </TableContainer>
      <NoticeBox>
        <ul className="blit-list">
          <li>
            <strong>일반사업비 : </strong>전 세계 157개 개발도상국의 유니세프
            사업비(보건, 에이즈, 식수와 위생, 영양, 교육, 어린이 보호, 사회통합
            등)
          </li>
          <li>
            <strong>지정사업비 : </strong>한국위원회가 직접 지원대상국과 사업을
            지정해서 보내는 사업비
          </li>
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3>
        <Tit size="s3-1">국가별 지정사업 현황</Tit>
      </h3>
      <MapContainer>
        <dd>
          <Image pcSrc={Map} mobileSrc={MapM} />
        </dd>
        <dt>
          <Image pcSrc={MapTxt} mobileSrc={MapTxtM} />
        </dt>
      </MapContainer>
    </Article>

    <Article>
      <h3>
        <Tit size="s3-1">주제별 지정사업 현황</Tit>
      </h3>
      <ProcessList itemData={processData} type="type-2" />
      <p className="tip">
        ※ 사단법인 유니세프한국위원회는 정기적인 외부회계법인의 회계감사를 통해,
        법인의 공익성과 기부금 운영 투명성을 추구하고 있습니다. <br />
        {` `}
        2018
        {` `}
        회계연도 재무제표 등 경영성과 또한 이촌회계법인의 실사 결과 적정하게
        표시되어 있음을 인정 받았습니다.
      </p>
    </Article>
  </Render>
);
export default ReportKorea2018;
